import { SideNavInterface } from "../../interfaces/side-nav.type";

export const ROUTES_CUSTOMER_DASHBOARD: SideNavInterface[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dashboard",
    submenu: [],
  },
  {
    path: "/seo/settings",
    title: "SEO settings",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "seo",
    submenu: [],
  },
  {
    path: "/seo/article-list",
    title: "Articles",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },
  {
    path: "/seo/category-list",
    title: "Article Categories",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "product",
    submenu: [],
  },
];
